.th-list {
  display: flex;
  justify-content: flex-start;
  font-weight: 500;
  margin: 1.2rem 2rem;
  text-align: center;
}
.tr-list-1 {
  min-width: 48px;
}
.res-tr {
  display: flex;
  justify-content: flex-start;
  text-align: center;
}
.tr-list {
  width: 20%;
}
.select-sync {
  display: flex;
}
.sync-btn {
  margin-left: 5px;
}
.config-modal {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 15px !important;
  width: 100%;
  margin: 0 auto;
}
.route-input {
  margin-right: 10px;
}
.li-order {
  display: flex;
  justify-content: space-between;
}
span.li-order-name {
  margin-right: 10px;
}
span.li-order-print {
  cursor: pointer;
}
.summary-order {
  display: flex;
  justify-content: flex-start;
}
.r4m-spinner svg {
  height: 1.3rem !important;
}
.route-li {
  display: flex;
  justify-content: space-between;
}
.sum-spinner {
  margin-left: 8px;
}
.order-spinner {
  margin-left: 5px;
}
.pr-icon {
  display: flex;
}
.dep .Polaris-TextField.Polaris-TextField--readOnly {
  display: none !important;
}
.route-url {
  padding: 15px;
  text-align: right;
}
.r4me-btn {
  margin-bottom: 10px;
}
.route-model-item {
  display: flex;
  justify-content: space-around;
}

@media (max-width: 767px) {
  .tr-list-1 {
    min-width: 0px;
  }
  .orders {
    min-width: fit-content;
  }
  .tr-list {
    min-width: 100px;
    width: unset;
  }
  .route-model-item {
    display: block;
  }
  .adddiv{
    min-width: 40px;
  }
}
